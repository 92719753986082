// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-banks-tsx": () => import("./../../../src/pages/banks.tsx" /* webpackChunkName: "component---src-pages-banks-tsx" */),
  "component---src-pages-cookies-policy-tsx": () => import("./../../../src/pages/cookies-policy.tsx" /* webpackChunkName: "component---src-pages-cookies-policy-tsx" */),
  "component---src-pages-error-tsx": () => import("./../../../src/pages/error.tsx" /* webpackChunkName: "component---src-pages-error-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-investment-tsx": () => import("./../../../src/pages/investment.tsx" /* webpackChunkName: "component---src-pages-investment-tsx" */),
  "component---src-pages-processing-tsx": () => import("./../../../src/pages/processing.tsx" /* webpackChunkName: "component---src-pages-processing-tsx" */),
  "component---src-pages-success-tsx": () => import("./../../../src/pages/success.tsx" /* webpackChunkName: "component---src-pages-success-tsx" */),
  "component---src-pages-summary-tsx": () => import("./../../../src/pages/summary.tsx" /* webpackChunkName: "component---src-pages-summary-tsx" */)
}

