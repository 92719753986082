module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-react-i18next/gatsby-browser.js'),
      options: {"plugins":[],"path":"./src/locales","languages":["pl"],"defaultLanguage":"pl","i18nextOptions":{"interpolation":{"escapeValue":false},"nsSeparator":":"}},
    },{
      plugin: require('../node_modules/gatsby-plugin-smoothscroll/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-gdpr-tracking/gatsby-browser.js'),
      options: {"plugins":[],"debug":false,"googleAnalytics":{"trackingId":"","autoStart":false,"anonymize":true,"controlCookieName":"gdpr-analytics-enabled","cookieFlags":"secure;samesite=none"},"googleAds":{"trackingId":"","anonymize":true,"controlCookieName":"gdpr-marketing-enabled","cookieFlags":"secure;samesite=none"},"facebookPixel":{"pixelId":"","controlCookieName":"gdpr-marketing-enabled"},"hotjar":{"trackingId":1976228,"snippetVersion":6,"controlCookieName":"gdpr-analytics-enabled"},"environments":["production","development"]},
    }]
